import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { Post } from '../components/pages/Post';
import { SideMenu } from '../components/SideMenu';
import styled from 'styled-components';

const IndexRoute = (props) => {
  const items = [];
  const { title, subtitle } = props.data.site.siteMetadata;
  const posts = props.data.allMarkdownRemark.edges;
  posts.forEach((post) => {
    items.push(<Post data={post} key={post.node.fields.slug} />);
  });

  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={subtitle} />
      </Helmet>
      <div className="columns is-flex-direction-row-reverse">
        <div className="column is-9-tablet">
          <Posts>{items}</Posts>
        </div>
        <SideMenu />
      </div>
    </Layout>
  );
};

const Posts = styled.div`
  > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export default IndexRoute;

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { layout: { eq: "post" }, draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            title
            date
            category
            description
          }
        }
      }
    }
  }
`;
